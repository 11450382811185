window.$ = $;

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import { Modal } from 'bootstrap';

window.alert = function(message, type = 'danger') {
    let $toast = $(`
        <div class="toast align-items-center text-white bg-${type} border-0" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-body d-flex" data-bs-dismiss="toast">
                <div class='me-2'>
                    ${message}
                </div>
                <button type="button" class="btn-close btn-close-white ms-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    `);
    $('.toast-container').prepend($toast);
    $toast.toast('show');
};

// przenosi pojedyncze znaki z końca linijki na początek następnej
export function addNbsps(el) {
    $(el).find(":not(iframe):not(style):not(script)").add(el).contents().filter((i, n) => n.nodeType == 3).each(function () {
        var startWhitespace = /^\s/.test(this.nodeValue) ? ' ' : '';
        var endWhitespace = /\s$/.test(this.nodeValue) ? ' ' : '';
        this.nodeValue = startWhitespace + (' ' + this.nodeValue + ' ').replace(/(\s\w)\s+/g, "$1\xA0").trim() + endWhitespace;
    });
};

addNbsps('body');

// $('[data-toggle="tooltip"]').tooltip();

$.fn.initJs = function () {
    $(window).trigger('init', this);
    return this;
};
Object.defineProperty($, "init", {
    set(fn) {
        $(window).on('init', function(e, el) {
            fn(el, e);
        });
    }
});
window.addEventListener('DOMContentLoaded', () => $('body').initJs());

// modal

$('.modal').on('show.bs.modal', function() {
    $('html').addClass('disable-scroll');
});

$('.modal').on('hide.bs.modal', function() {
    $('html').removeClass('disable-scroll');
});

// s recommended

let sRecommendedSlider;

$('.s-recommended__slider .swiper').each(function(){

    sRecommendedSlider = new Swiper($(this)[0], {
        slidesPerView: 1,
        speed: 500,
        loop: true,
        simulateTouch: false,
        navigation: {
            prevEl: $(this).siblings('.swiper-button-prev')[0],
            nextEl: $(this).siblings('.swiper-button-next')[0]
        },
        pagination: {
            el: $(this).siblings('.swiper-pagination')[0],
            clickable: true
        },
        modules: [Navigation, Pagination]
    });

    // sRecommendedSlider.on('slideChange', function (e) {
    //     console.log('eee');
    //     // console.log($(self));
    //     // console.log($(this));
    // });

});

$('.swiper-button-prev').click(function(e){

    e.preventDefault();
    if ($(this).parent().hasClass('s-recommended__slider')) {
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                event: 'userAction',
                category: 'recommended',
                action: 'left-arrow_click',
                label: 'kinder-cards-2024',
            });
        }
    }
    if ($(this).parent().hasClass('s-magic__slider')) {
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                event: 'userAction',
                category: 'magic-lessons',
                action: 'left-arrow_click',
                label: 'kinder-cards-2024',
            });
        }
    }

});

$('.swiper-button-next').click(function(e){

    e.preventDefault();
    if ($(this).parent().hasClass('s-recommended__slider')) {
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                event: 'userAction',
                category: 'recommended',
                action: 'right-arrow_click',
                label: 'kinder-cards-2024',
            });
        }
    }
    if ($(this).parent().hasClass('s-magic__slider')) {
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                event: 'userAction',
                category: 'magic-lessons',
                action: 'right-arrow_click',
                label: 'kinder-cards-2024',
            });
        }
    }

});

$('.swiper-pagination-bullet').click(function(e){

    e.preventDefault();
    if ($(this).parent().parent().hasClass('s-recommended__slider')) {
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                event: 'userAction',
                category: 'recommended',
                action: 'dot_click',
                label: 'kinder-cards-2024',
            });
        }
    }
    if ($(this).parent().parent().hasClass('s-magic__slider')) {
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                event: 'userAction',
                category: 'magic-lessons',
                action: 'dot_click',
                label: 'kinder-cards-2024',
            });
        }
    }

});

// s magic

let sMagicSlider;

$('.s-magic__slider .swiper').each(function(){

    sMagicSlider = new Swiper($(this)[0], {
        slidesPerView: 1,
        speed: 500,
        loop: true,
        simulateTouch: false,
        navigation: {
            prevEl: $(this).siblings('.swiper-button-prev')[0],
            nextEl: $(this).siblings('.swiper-button-next')[0]
        },
        pagination: {
            el: $(this).siblings('.swiper-pagination')[0],
            clickable: true
        },
        modules: [Navigation, Pagination]
    });

});

// s corner

let sCornerSlider;

$('.s-corner__slider .swiper').each(function(){

    let that = $(this);

    sCornerSlider = new Swiper(that[0], {
        slidesPerView: 'auto',
        speed: 500,
        loop: true,
        navigation: {
            prevEl: $(this).siblings('.swiper-button-prev')[0],
            nextEl: $(this).siblings('.swiper-button-next')[0]
        },
        modules: [Navigation]
    });

    function toggleSlider(widthWindow) {
        widthWindow > 767 ? that[0].swiper.disable() : that[0].swiper.enable();
    }

    let widthWindow = $(window).outerWidth();

    toggleSlider(widthWindow);

    $(window).resize(function(){

        let widthWindow = $(window).outerWidth();

        toggleSlider(widthWindow);

    });

});

// video

$('.s-recommended__item, .s-magic__item, .s-meet__video').click(function(e){

    e.preventDefault();

    let href = $(this).attr('href'),
        videoTitle = $(this).data('video-title'),
        category = $(this).data('category'),
        modal = $('.m-video'),
        modalVideo = modal.find('video');

        modalVideo.attr('src', href);
    if(modalVideo[0].paused) {
        modalVideo[0].play();

        let startedPrize = false;
        let twentyFivePrize = false;
        let halfPrize = false;
        let seventyFivePrize = false;
        let videoCompletePrize = false;
        let video = modalVideo[0];
        // console.log(videoTitle);
        // console.log(category);
        video.ontimeupdate = function() {
            let percentage = video.currentTime / video.duration * 100;
            if (percentage > 0 && !startedPrize) {
                startedPrize = true;
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: '' + category + '',
                        action: 'video-play-start',
                        label: 'kinder-cards-2024',
                        item: '' + videoTitle + ''
                    });
                }
            }

            if (percentage > 25 && !twentyFivePrize) {
                twentyFivePrize = true;
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: '' + category + '',
                        action: 'video-play-25',
                        label: 'kinder-cards-2024',
                        item: '' + videoTitle + ''
                    });
                }
            }

            if (percentage > 50 && !halfPrize) {
                halfPrize = true;
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: '' + category + '',
                        action: 'video-play-50',
                        label: 'kinder-cards-2024',
                        item: '' + videoTitle + ''
                    });
                }
            }

            if (percentage > 75 && !seventyFivePrize) {
                seventyFivePrize = true;
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: '' + category + '',
                        action: 'video-play-75',
                        label: 'kinder-cards-2024',
                        item: '' + videoTitle + ''
                    });
                }
            }

            if (percentage === 100 && !videoCompletePrize) {
                videoCompletePrize = true;
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: '' + category + '',
                        action: 'video-play-100',
                        label: 'kinder-cards-2024',
                        item: '' + videoTitle + ''
                    });
                }
            }
        };
    } else {
        modalVideo[0].pause();
    }

    // modalVideo.attr('src', href)[0].play();

});

$('.m-video').on('hidden.bs.modal', function(e) {
    $(this).find('video')[0].pause();
});