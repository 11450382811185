$(function () {
    $('[data-event]').click(function(e) {
        var key = $(this).attr('data-event');
        var item = $(this).attr('data-item');
        // console.log(item);
        var events = {
            'recommended_click': {
                event: 'userAction',
                category: 'nav_bar',
                action: 'recommended_click',
                label: 'kinder-cards-2024'
            },
            'magic-lessons_click': {
                event: 'userAction',
                category: 'nav_bar',
                action: 'magic-lessons_click',
                label: 'kinder-cards-2024'
            },
            'corner-magic_click': {
                event: 'userAction',
                category: 'nav_bar',
                action: 'corner-magic_click',
                label: 'kinder-cards-2024'
            },
            'today-conjure_click': {
                event: 'userAction',
                category: 'nav_bar',
                action: 'today-conjure_click',
                label: 'kinder-cards-2024'
            },
            'pdf-download': {
                event: 'userAction',
                category: 'corner-magic',
                action: 'pdf-download',
                label: 'kinder-cards-2024',
                item: '' + item + ''
            },
            // 'main-page_click': {
            //     event: 'userAction',
            //     category: 'nav_bar',
            //     action: 'main-page_click',
            //     label: 'kids_talents_2024'
            // },
            // 'prizes_click': {
            //     event: 'userAction',
            //     category: 'nav_bar',
            //     action: 'prizes_click',
            //     label: 'kids_talents_2024'
            // },
            // 'rules_click': {
            //     event: 'userAction',
            //     category: 'nav_bar',
            //     action: 'rules_click',
            //     label: 'kids_talents_2024'
            // },
            // 'application_click': {
            //     event: 'userAction',
            //     category: 'nav_bar',
            //     action: 'application_click',
            //     label: 'kids_talents_2024'
            // },
            // 'products_click': {
            //     event: 'userAction',
            //     category: 'nav_bar',
            //     action: 'products_click',
            //     label: 'kids_talents_2024'
            // },
            // 'joy_click': {
            //     event: 'userAction',
            //     category: 'nav_bar',
            //     action: 'joy_click',
            //     label: 'kids_talents_2024'
            // },
            // 'contact_click': {
            //     event: 'userAction',
            //     category: 'nav_bar',
            //     action: 'contact_click',
            //     label: 'kids_talents_2024'
            // },
            // 'joinin_click': {
            //     event: 'userAction',
            //     category: 'homepage',
            //     action: 'joinin_click',
            //     label: 'kids_talents_2024'
            // },
            // 'download_lottery-terms': {
            //     event: 'userAction',
            //     category: 'rules',
            //     action: 'download_lottery-terms',
            //     label: 'kids_talents_2024'
            // },
            // 'download_competition-terms': {
            //     event: 'userAction',
            //     category: 'rules',
            //     action: 'download_competition-terms',
            //     label: 'kids_talents_2024'
            // },
            // 'download_allegrocard-terms': {
            //     event: 'userAction',
            //     category: 'rules',
            //     action: 'download_allegrocard-terms',
            //     label: 'kids_talents_2024'
            // },
            // 'joinin-corner_click': {
            //     event: 'userAction',
            //     category: 'rules',
            //     action: 'joinin-corner_click',
            //     label: 'kids_talents_2024'
            // },
            // 'join-later-registration_click': {
            //     event: 'userAction',
            //     category: 'application',
            //     action: 'join-later-registration_click',
            //     label: 'kids_talents_2024'
            // },
            // 'join-now-registration_click': {
            //     event: 'userAction',
            //     category: 'application',
            //     action: 'join-now-registration_click',
            //     label: 'kids_talents_2024'
            // },
            // 'send-competition-later': {
            //     event: 'userAction',
            //     category: 'application',
            //     action: 'send-competition-later',
            //     label: 'kids_talents_2024'
            // },
            // 'mail_click': {
            //     event: 'userAction',
            //     category: 'contact',
            //     action: 'mail_click',
            //     label: 'kids_talents_2024'
            // },
            // 'message_click': {
            //     event: 'userAction',
            //     category: 'contact',
            //     action: 'message_click',
            //     label: 'kids_talents_2024'
            // },
            //
            // // 'contact-us_click': {
            // //     event: 'userAction',
            // //     category: 'contact',
            // //     action: 'contact-us_click',
            // //     label: 'kinderdziendziecka_2024'
            // // },
            // 'join-in-click-btn': {
            //     event: 'userAction',
            //     category: 'WebButton',
            //     action: 'join-in-click',
            //     label: 'kinderdziendziecka_2024'
            // },
            // 'button-join-in-click' : {
            //     event: 'userAction',
            //     category: 'main-page',
            //     action: 'join-in_click',
            //     label: 'kinderdziendziecka_2024'
            // },
            // 'join-in_contest': {
            //     event: 'userAction',
            //     category: 'join-in_contest',
            //     action: 'contest_join_now_click',
            //     label: 'kinderdziendziecka_2024'
            // },
            // 'join-in_contest_later': {
            //     event: 'userAction',
            //     category: 'join-in_contest',
            //     action: 'contest_join_later_click',
            //     label: 'kinderdziendziecka_2024'
            // },
            // 'email_click': {
            //     event: 'userAction',
            //     category: 'contact',
            //     action: 'email_click',
            //     label: 'kinderdziendziecka_2024'
            // },
            // 'floating_button_click': {
            //     event: 'userAction',
            //     category: 'WebButton',
            //     action: 'join-in_click',
            //     label: 'kinderdziendziecka_2024'
            // },
            // 'rule3_more_click': {
            //     event: 'userAction',
            //     category: 'form',
            //     action: 'read-more-information-clause_click',
            //     label: 'kinderdziendziecka_2024'
            // },
            // 'rule4_more_click': {
            //     event: 'userAction',
            //     category: 'form',
            //     action: 'read-more-personal-data_click',
            //     label: 'kinderdziendziecka_2024'
            // },
            // 'check_click': {
            //     event: 'userAction',
            //     category: 'joy-being-together',
            //     action: 'check_click',
            //     label: 'kinderdziendziecka_2024'
            // },
            // 'contact-us_click': {
            //     event: 'userAction',
            //     category: 'contact',
            //     action: 'contact-us_click',
            //     label: 'kinderdziendziecka_2024'
            // }
        }

        if (typeof dataLayer !== 'undefined') {
            if (typeof events[key] !== 'undefined') {
                dataLayer.push(events[key]);
                // dataLayer.push({
                //     'event': 'gate_pass'
                // });
            }
        }
    });

    // $('input[name="personal_data[files][paragon]"]').on('change', function () {
    //     if (typeof dataLayer !== 'undefined') {
    //         dataLayer.push({
    //             event: 'userAction',
    //             category: 'form',
    //             action: 'recipe_sent',
    //             label: 'kinderdziendziecka_2024'
    //         });
    //     }
    // });
    //
    // $('[data-event-fill]').focusout(function(e) {
    //     var key = $(this).data('event-fill');
    //     var events = {
    //         'code_fill': {
    //             event: 'userAction',
    //             category: 'application',
    //             action: 'code_fill',
    //             label: 'kids_talents_2024'
    //         },
    //         'name-registration_fill': {
    //             event: 'userAction',
    //             category: 'application',
    //             action: 'name-registration_fill',
    //             label: 'kids_talents_2024'
    //         },
    //         'surname-registration_fill': {
    //             event: 'userAction',
    //             category: 'application',
    //             action: 'surname-registration_fill',
    //             label: 'kids_talents_2024'
    //         },
    //         'email-registration_fill': {
    //             event: 'userAction',
    //             category: 'application',
    //             action: 'email-registration_fill',
    //             label: 'kids_talents_2024'
    //         },
    //         'phone-number-registration_fill': {
    //             event: 'userAction',
    //             category: 'application',
    //             action: 'phone-number-registration_fill',
    //             label: 'kids_talents_2024'
    //         },
    //         'child-name-competition_fill': {
    //             event: 'userAction',
    //             category: 'application',
    //             action: 'child-name-competition_fill',
    //             label: 'kids_talents_2024'
    //         },
    //         'child-age-competition_choose': {
    //             event: 'userAction',
    //             category: 'application',
    //             action: 'child-age-competition_choose',
    //             label: 'kids_talents_2024'
    //         },
    //         'decription-competition_fill': {
    //             event: 'userAction',
    //             category: 'application',
    //             action: 'decription-competition_fill',
    //             label: 'kids_talents_2024'
    //         },
    //         'name_fill-in': {
    //             event: 'userAction',
    //             category: 'contact',
    //             action: 'name_fill-in',
    //             label: 'kids_talents_2024'
    //         },
    //         'email_fill': {
    //             event: 'userAction',
    //             category: 'contact',
    //             action: 'email_fill',
    //             label: 'kids_talents_2024'
    //         },
    //         'message_fill': {
    //             event: 'userAction',
    //             category: 'contact',
    //             action: 'message_fill',
    //             label: 'kids_talents_2024'
    //         },
    //
    //
    //         'contact_name_fill': {
    //             event: 'userAction',
    //             category: 'contact',
    //             action: 'name_fill',
    //             label: 'kinderdziendziecka_2024'
    //         },
    //         'message-subject_fill' : {
    //             event: 'userAction',
    //             category: 'contact',
    //             action: 'message-subject_fill',
    //             label: 'kinderdziendziecka_2024'
    //         },
    //         'contact_email_fill': {
    //             event: 'userAction',
    //             category: 'contact',
    //             action: 'email_fill',
    //             label: 'kinderdziendziecka_2024'
    //         },
    //         'contact_massage_fill': {
    //             event: 'userAction',
    //             category: 'contact',
    //             action: 'massage_fill',
    //             label: 'kinderdziendziecka_2024'
    //         },
    //         'winner_name_fill': {
    //             event: 'userAction',
    //             category: 'join-in_delivery',
    //             action: 'name_fill',
    //             label: 'kinderdziendziecka_2024'
    //         },
    //         'winner_surname_fill': {
    //             event: 'userAction',
    //             category: 'join-in_delivery',
    //             action: 'surname_fill',
    //             label: 'kinderdziendziecka_2024'
    //         },
    //         'winner_phone_nr_fill': {
    //             event: 'userAction',
    //             category: 'join-in_delivery',
    //             action: 'phone_nr_fill',
    //             label: 'kinderdziendziecka_2024'
    //         },
    //         'winner_email_fill': {
    //             event: 'userAction',
    //             category: 'join-in_delivery',
    //             action: 'email_fill',
    //             label: 'kinderdziendziecka_2024'
    //         },
    //         'winner_street_fill': {
    //             event: 'userAction',
    //             category: 'join-in_delivery',
    //             action: 'street_fill',
    //             label: 'kinderdziendziecka_2024'
    //         },
    //         'winner_building_nr_fill': {
    //             event: 'userAction',
    //             category: 'join-in_delivery',
    //             action: 'building_nr_fill',
    //             label: 'kinderdziendziecka_2024'
    //         },
    //         'winner_apartm_nr_fill': {
    //             event: 'userAction',
    //             category: 'join-in_delivery',
    //             action: 'apartm_nr_fill',
    //             label: 'kinderdziendziecka_2024'
    //         },
    //         'winner_zip_fill': {
    //             event: 'userAction',
    //             category: 'join-in_delivery',
    //             action: 'zip_fill',
    //             label: 'kinderdziendziecka_2024'
    //         },
    //         'winner_city_fill': {
    //             event: 'userAction',
    //             category: 'join-in_delivery',
    //             action: 'city_fill',
    //             label: 'kinderdziendziecka_2024'
    //         },
    //     }
    //
    //     if (typeof dataLayer !== 'undefined') {
    //         if (typeof events[key] !== 'undefined') {
    //             if ($(this).val()) {
    //                 dataLayer.push(events[key]);
    //             }
    //         }
    //     }
    // });
    //
    // $('#form-agree-all').on('change', function () {
    //     if ($('.application-rule:checked').length === $('.application-rule').length) {
    //         if (typeof dataLayer !== 'undefined') {
    //             dataLayer.push({
    //                 event: 'userAction',
    //                 category: 'application',
    //                 action: 'consent-registration_fill',
    //                 label: 'kids_talents_2024'
    //             });
    //         }
    //     }
    //     if ($('.competition-rule:checked').length === $('.competition-rule').length) {
    //         if (typeof dataLayer !== 'undefined') {
    //             dataLayer.push({
    //                 event: 'userAction',
    //                 category: 'application',
    //                 action: 'consent-competition_fill',
    //                 label: 'kids_talents_2024'
    //             });
    //         }
    //     }
    // });
    //
    // $('.application-rule').on('change', function () {
    //     if ($('.application-rule:checked').length === $('.application-rule').length) {
    //         if (typeof dataLayer !== 'undefined') {
    //             dataLayer.push({
    //                 event: 'userAction',
    //                 category: 'application',
    //                 action: 'consent-registration_fill',
    //                 label: 'kids_talents_2024'
    //             });
    //         }
    //     }
    // });
    //
    // $('#form-agree-6').on('change', function () {
    //     if($(this).is(':checked')) {
    //         if (typeof dataLayer !== 'undefined') {
    //             dataLayer.push({
    //                 event: 'userAction',
    //                 category: 'contact',
    //                 action: 'consent_click',
    //                 label: 'kids_talents_2024'
    //             });
    //         }
    //     }
    // });
    // //
    // // $('.optional-application-rule').on('change', function () {
    // //     if ($('.optional-application-rule:checked').length === $('.optional-application-rule').length) {
    // //         if (typeof dataLayer !== 'undefined') {
    // //             dataLayer.push({
    // //                 event: 'userAction',
    // //                 category: 'form',
    // //                 action: 'consent_click',
    // //                 label: 'kinderdziendziecka_2024'
    // //             });
    // //         }
    // //     }
    // // });
    //
    // $('.winner_rule').on('change', function () {
    //     if ($('.winner_rule:checked').length === $('.winner_rule').length) {
    //         if (typeof dataLayer !== 'undefined') {
    //             dataLayer.push({
    //                 event: 'userAction',
    //                 category: 'join-in_delivery',
    //                 action: 'consent_terms_conditions_click',
    //                 label: 'kinderdziendziecka_2024'
    //             });
    //         }
    //     }
    // });
    //
    // $('.competition-rule').on('change', function () {
    //     if ($('.competition-rule:checked').length === $('.competition-rule').length) {
    //         if (typeof dataLayer !== 'undefined') {
    //             dataLayer.push({
    //                 event: 'userAction',
    //                 category: 'application',
    //                 action: 'consent-competition_fill',
    //                 label: 'kids_talents_2024'
    //             });
    //         }
    //     }
    // });
    //
    // var landingSection = $('#landing').offset()?.top;
    // var applicationSection = $('#application').offset()?.top;
    // var productsSection = $('#products').offset()?.top;
    // var prizesSection = $('#prizes').offset()?.top;
    // var rulesSection = $('#rules').offset()?.top;
    // var joySection = $('#joy').offset()?.top;
    // var contactSection = $('#contact-section').offset().top;
    var polecaneSection = $('#polecane').offset()?.top;
    var lekcjeMagiiSection = $('#lekcje-magii').offset()?.top;
    var zakatekMagiiSection = $('#zakatek-magii').offset()?.top;
    var dzisWyczarujcieSection = $('#dzis-wyczarujcie').offset()?.top;

    var section = '';
    $(window).on('scroll', function() {
        var y_scroll_pos = window.pageYOffset;
        if(y_scroll_pos > polecaneSection - 120 && y_scroll_pos < polecaneSection + $('#polecane').height()) {
            if (section == '' || section != 'polecane') {
                console.log('polecane')
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: 'recommended',
                        action: 'section_view',
                        label: 'kinder-cards-2024'
                    });
                }
            }
            section = 'polecane';
        }
        else if(y_scroll_pos > lekcjeMagiiSection - 120 && y_scroll_pos < lekcjeMagiiSection + $('#lekcje-magii').height()) {
            if (section == '' || section != 'lekcje-magii') {
                console.log('lekcje-magii')
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: 'magic-lessons',
                        action: 'section_view',
                        label: 'kinder-cards-2024'
                    });
                }
            }
            section = 'lekcje-magii';
        }
        else if(y_scroll_pos > zakatekMagiiSection - 120 && y_scroll_pos < zakatekMagiiSection + $('#zakatek-magii').height()) {
            if (section == '' || section != 'zakatek-magii') {
                console.log('zakatek-magii')
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: 'corner-magic',
                        action: 'section_view',
                        label: 'kinder-cards-2024'
                    });
                }
            }
            section = 'zakatek-magii';
        }
        else if(y_scroll_pos > dzisWyczarujcieSection - 120 && y_scroll_pos < dzisWyczarujcieSection + $('#dzis-wyczarujcie').height()) {
            if (section == '' || section != 'dzis-wyczarujcie') {
                console.log('dzis-wyczarujcie');
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: 'today-conjure',
                        action: 'section_view',
                        label: 'kinder-cards-2024'
                    });
                }
            }
            section = 'dzis-wyczarujcie';
        }
    //     if(y_scroll_pos > joySection - 120 && y_scroll_pos < joySection + $('#joy').height()) {
    //         if (section == '' || section != 'joy') {
    //             if (typeof dataLayer !== 'undefined') {
    //                 dataLayer.push({
    //                     event: 'userAction',
    //                     category: 'joy',
    //                     action: 'section_view',
    //                     label: 'kids_talents_2024'
    //                 });
    //             }
    //         }
    //         section = 'joy';
    //     }
    //     if(y_scroll_pos > productsSection - 120 && y_scroll_pos < productsSection + $('#products').height()) {
    //         if (section == '' || section != 'products') {
    //             if (typeof dataLayer !== 'undefined') {
    //                 dataLayer.push({
    //                     event: 'userAction',
    //                     category: 'products',
    //                     action: 'section_view',
    //                     label: 'kids_talents_2024'
    //                 });
    //             }
    //         }
    //         section = 'products';
    //     }
    //
    //     if(y_scroll_pos > contactSection - 120 - $('#contact-section').height() && y_scroll_pos < contactSection + $('#contact-section').height()) {
    //         if (section == '' || section != 'contact') {
    //             if (typeof dataLayer !== 'undefined') {
    //                 dataLayer.push({
    //                     event: 'userAction',
    //                     category: 'contact',
    //                     action: 'section_view',
    //                     label: 'kids_talents_2024'
    //                 });
    //             }
    //         }
    //         section = 'contact';
    //     }
    });

    // $('.s-recommended__item2').on('click', function (e) {
    //     // new bootstrap.Modal(`#modal-video1`, {}).show();
    //     const video = $(this).find('video')[0];
    //
    //     console.log(video);
    //     if (!$(this).find('video').attr('src')) {
    //         $(this).find('video').prop('src', $(this).find('video').attr('data-src'));
    //         video.load();
    //     }
    //
    //     $(this).find('svg').addClass('d-none');
    //
    //     if(video.paused) {
    //         video.play();
    //         $(this).addClass('playing');
    //
    //         let startedPrize = false;
    //         let twentyFivePrize = false;
    //         let halfPrize = false;
    //         let seventyFivePrize = false;
    //         let videoCompletePrize = false;
    //
    //         video.ontimeupdate = function() {
    //             let percentage = video.currentTime / video.duration * 100;
    //             if (percentage > 0 && !startedPrize) {
    //                 startedPrize = true;
    //                 if (typeof dataLayer !== 'undefined') {
    //                     dataLayer.push({
    //                         event: 'userAction',
    //                         category: 'prizes',
    //                         action: 'video_seen_0%',
    //                         label: 'kinder-dzien-dziecka_2024',
    //                     });
    //                 }
    //             }
    //
    //             if (percentage > 25 && !twentyFivePrize) {
    //                 twentyFivePrize = true;
    //                 if (typeof dataLayer !== 'undefined') {
    //                     dataLayer.push({
    //                         event: 'userAction',
    //                         category: 'prizes',
    //                         action: 'video_seen_25%',
    //                         label: 'kinder-dzien-dziecka_2024',
    //                     });
    //                 }
    //             }
    //
    //             if (percentage > 50 && !halfPrize) {
    //                 halfPrize = true;
    //                 if (typeof dataLayer !== 'undefined') {
    //                     dataLayer.push({
    //                         event: 'userAction',
    //                         category: 'prizes',
    //                         action: 'video_seen_50%',
    //                         label: 'kinder-dzien-dziecka_2024',
    //                     });
    //                 }
    //             }
    //
    //             if (percentage > 75 && !seventyFivePrize) {
    //                 seventyFivePrize = true;
    //                 if (typeof dataLayer !== 'undefined') {
    //                     dataLayer.push({
    //                         event: 'userAction',
    //                         category: 'prizes',
    //                         action: 'video_seen_75%',
    //                         label: 'kinder-dzien-dziecka_2024',
    //                     });
    //                 }
    //             }
    //
    //             if (percentage === 100 && !videoCompletePrize) {
    //                 videoCompletePrize = true;
    //                 if (typeof dataLayer !== 'undefined') {
    //                     dataLayer.push({
    //                         event: 'userAction',
    //                         category: 'prizes',
    //                         action: 'video_seen_100%',
    //                         label: 'kinder-dzien-dziecka_2024',
    //                     });
    //                 }
    //             }
    //         };
    //     } else {
    //         video.pause();
    //         $(this).find('svg').removeClass('d-none');
    //         $(this).removeClass('playing');
    //     }
    // });
});
