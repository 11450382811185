const navigation = document.getElementById('navigation');
const navigationToggler = navigation?.querySelector('.navbar-toggler');
const dropdown = document.getElementById('navigation-dropdown');

dropdown?.querySelectorAll('.nav-item').forEach((item) => {
	if (!item.classList.contains('dropdown')) {
		item.addEventListener('click', () => {
			toggleDropdown();
		});
	}
});

const toggleDropdown = () => {
	if (navigationToggler?.getAttribute('aria-expanded') == 'true') {
		navigationToggler?.click();
	}
};

// header primary

$('.header-primary__hamburger .hamburger').click(function(e){

    e.preventDefault();

    $(this).toggleClass('hamburger--active');
    $('.header-primary').toggleClass('header-primary--is-open');

    $('html').toggleClass('disable-scroll');

});

$(window).resize(function(){

    let widthWindow = $(this).outerWidth();

    if(widthWindow > 767 && $('.header-primary').hasClass('header-primary--is-open')) {
        $('.header-primary__hamburger .hamburger').removeClass('hamburger--active');
        $('.header-primary').removeClass('header-primary--is-open');
        $('html').removeClass('disable-scroll');
    }

});

$('.header-primary__nav a').click(function(e){

    let href = $(this).attr('href'),
        sectionName = href.split('#')[1],
        section = $('#' + sectionName),
        sectionExist = section.length,
        widthWindow = $(window).outerWidth();

    if(sectionExist && widthWindow < 768) {
        $('.header-primary__hamburger .hamburger').removeClass('hamburger--active');
        $('.header-primary').removeClass('header-primary--is-open');
        $('html').removeClass('disable-scroll');
    }

});

let lastId,
    topMenu = $(".header-primary__nav"),
    topMenuHeight = $(".header-primary").outerHeight(),
    menuItems = topMenu.find('a:not([href="#"])'),
    scrollItems = menuItems.map(function () {

        let href = $(this).attr('href'),
            sectionName = href.split('#')[1],
            section = $('#' + sectionName),
            sectionExist = section.length;

        if(sectionExist) {

            $(this).attr('data-name', sectionName);

            return $(section);

        }

    });

$(window).scroll(function(){

    let fromTop = $(this).scrollTop() + topMenuHeight,
        cur = scrollItems.map(function() {
            if ($(this).offset().top - 200 < fromTop && $(this).offset().top - 200 + $(this).outerHeight() > fromTop)
            return this;
    });

    cur = cur[cur.length - 1];

    let id = cur && cur.length ? cur[0].id : "";

    if(lastId !== id) {

        lastId = id;

        menuItems
            .parent().removeClass("active")
            .end().filter('[data-name="' + id + '"]').parent().addClass("active");

    }

});

// scroll to

$('a[href*="#"]').not('[href="#"]').click(function(e) {

	e.preventDefault();

    let href = $(this).attr('href'),
        sectionName = href.split('#')[1],
        section = $('#' + sectionName),
        sectionOffsetTop = section.offset().top - $('.header-primary').outerHeight();

	$('html, body').animate({
		scrollTop: sectionOffsetTop
	}, 300);

	return false;

});